body {
  /* background-image: url('/images/cards/turf03.jpg'); */
}

.app-logo {
  margin-right: 15px;
}
.ba-login-card-warpper {
  margin-top: 20%;
}

.ba-dialog-content {
  cursor: default;
}

.ba-login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ba-textField {
  width: 300px;
  margin-top: 5px !important;
  padding: 10px;
}

.ba-game-icon {
  width: 30px;
  height: 30px;
  padding: 5px;
}

.ba-login-btn-wrapper {
  margin-top: 25px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.ba-login-span {
  padding-top: 20px;
  font-weight: 600 !important;
  font-size: 20px !important;
}

.ba-login-footer-span {
  padding-top: 25px;
  text-decoration: underline;
  font: normal normal normal 14px/28px roboto;
  letter-spacing: 0px;
  color: #333333;
  opacity: 0.53;
  display: flex;
  cursor: pointer;
  flex-direction: column !important;
}

.ba-login-signup-span {
  margin-top: 10px !important;
}

.ba-login-btn {
  width: 320px !important;
}

/* sign up */
.ba-signup-card-warpper {
  margin-top: 10%;
  height: 800px;
}

/* footer */
.ba-footer {
  display: flex;
  align-items: center;
  font-size: 12px !important;
}

.ba-footer-icon {
  width: 25px !important;
  margin-right: 10px;
  color: #fff;
}

.ba-footer-background {
  /*background-color: #7C953A !important;*/
  background-image: url("../images/bg01.jpg");
}

/* header */
.ba-header-background {
  /* background-color: #505E3B !important;*/
  background-image: url("../images/bg02.jpg");
  color: #ffbb17 !important;
}

.ba-header-listLink {
  text-decoration: none;
  color: #706b6b;
}

.ba-header-menuList {
  padding-left: 10px;
}

/* forgot password */
.ba-forgot-textField {
  width: 300px;
  margin-top: 25px !important;
}

/* GROUND LIST */
.ba-gl-card {
  display: flex;
  justify-content: center;
}

.ba-gl-cardWrapper {
  padding-bottom: 50px;
  margin-top: 100px !important;
}

.my-header-class {
  background-color: blue;
}

.my-footer-class {
  background-color: green;
}

.ba-login-header {
  width: 300px;
}

/* TURF */
.bp-turf-card {
  width: 345px;
  padding-bottom: 15px;
}

/* BOOKING LIST */
.bp-bookingList-box {
  height: 631px;
  width: 100%;
}

/* DRAWER */
.ba-drawer-header {
  min-width: 64px;
  min-height: 64px;
  background-color: #505e3b;
}

/* DATA TABLE  */
.ba-table-title {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}

.ba-table-btn {
  margin-top: 20px !important;
}

/* ADDBOOKING */
.ba-booking-card-warpper {
  margin-top: 22%;
  height: 696px;
}

.ba-root-container {
  max-width: 1800px !important;
  /* background-image: url('../images/samabk.png'); 
    background-repeat: no-repeat;*/
}

.ba-booking-btn-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.ba-booking-rst-btn {
  margin-right: 10px !important;
}

.ba-booking-timeSlot {
  max-width: 300px;
}

.ba-booking-wrapper {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ba-booking-grid-btn {
  display: flex;
  justify-content: flex-end;
}

.ba-booking-table-wrapper {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ba-booking-progressbar {
  margin-top: 65px !important;
}

.ba-booking-btn-warpper {
  margin-top: 10px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin-bottom: 25px !important;
  display: flex;
  justify-content: flex-end;
}

.ba-booking-heading-wrapper {
  /* margin-top: 60px !important; */
  padding-left: 25px !important;
}

/* BOOKING PRICE */
.ba-booking-price {
  margin-top: 5px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ba-booking-price-input {
  max-width: 300px;
}

.ba-txt-grand-total {
  font-size: 1.1rem;
  font-weight: bold;
}

.ba-txt-game-plan {
  margin-top: 5px !important;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
}

.ba-form-card {
  margin-top: 75px !important;
  margin-bottom: 20px !important;
  /* margin-left:10px !important;
    margin-right: 10px !important; */
}

.ba-confirmPassword {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.ba-bookingTimeSlot-modal {
  height: 360px !important;
}

.ba-timeslot-modal {
  display: flex;
  justify-content: space-around;
  font-weight: 600;
}

.ba-turf-card {
  min-width: 100% !important;
}

.ba-date-today {
  /* background-color: rgb(122, 192, 122); */
  color: green;
}

.ba-date-past {
  /* background-color: #746e6e9a; */
  color: black;
}

.ba-date-future {
  /* background-color: #94e1fd; */
  color: blue;
}
